import React from 'react';
import PaidIcon from '@mui/icons-material/Paid';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div>
      <div className='Footer'>
        <div className='left-footer'>
          <h3 style={{ fontSize: '2rem', whiteSpace: 'nowrap', color: 'white', marginBottom: '10px' }}>
            <PaidIcon className="paid-icon-large" fontSize="large" style={{ verticalAlign: 'middle', marginBottom: '5px', color: 'white' }} /> Golden Metrics
          </h3>
          <p style={{ fontSize: '1rem', color: 'white', margin: '0' }}> Have a question or want to connect? Use these links or feel free to reach out to us directly.</p>
          <p style={{ fontSize: '1rem', whiteSpace: 'nowrap', color: 'white' }}>
            <PhoneIcon className="footer-icon" fontSize="large" style={{ verticalAlign: 'middle', marginBottom: '5px', color: 'white' }} /> 1-717-723-5127
          </p>
          <p style={{ fontSize: '1rem', whiteSpace: 'nowrap', color: 'white' }}>
            <LocationOnIcon className="footer-icon" fontSize="large" style={{ verticalAlign: 'middle', marginBottom: '5px', color: 'white' }} /> HQ Salt Lake City, UT
          </p>
          <p style={{ fontSize: '1rem', whiteSpace: 'nowrap', color: 'white' }}>
            <EmailIcon className="footer-icon" fontSize="large" style={{ verticalAlign: 'middle', marginBottom: '5px', color: 'white' }} /> thegoldenmetrics@gmail.com
          </p>
        </div>

        <div className='right-footer'>
        </div>
      </div>
      <p style={{ textAlign: 'center', color: 'white' }}>
        Copyright © {currentYear} The Golden Metrics. All rights reserved.
      </p>
    </div>
  );
}
